import React from 'react';

function Helpers (props) { 
    return (
        <div id="helpers" name="helpers" ref={props.refProp}>
            <div className="section-info">
                <div className="title">Helpers/Volunteers</div>
                <div>
                    <span className="red-text-2">Volunteer to help those that need it most by</span>
                </div>
                <div>
                    <span className="red-text-2">running errands in your local community. </span>
                </div>
            </div>
            <div className="instruction-item" >
                <div className="instruction-image">
                    <img src="./images/Helper_Step3.png" />
                </div>
                <div className="instruction-description">
                    <div className="instruction-title">Helper</div>
                    <div className="instruction-text" style={{textAlign: 'left'}}>
                        1. Press the Help tab on the bottom left of the app.
                    </div>
                    <div className="instruction-text instruction-text-mod">
                        2. Select 'Give Help!'
                    </div>
                </div>
            </div>
            <div className="instruction-item-block">
                <div className="instruction-item2">
                    <div className="instruction-image smaller-image">
                        <img src="./images/Helper_step3.1.png" />
                    </div>
                    <div className="instruction-title">Helper - Explore Lists</div>
                    <div className="instruction-description">
                        <div className="instruction-text">3. Explore all help posts</div>
                        <div className="instruction-text">4. Select the one you want to help and click on 'PitchN'</div>
                    </div>
                </div>
                <div className="instruction-item2">
                    <div className="instruction-image">
                        <img src="./images/Helper_Part3.2.png" />
                    </div>
                    <div className="instruction-title">Helper - Run Errands</div>
                    <div className="instruction-description">
                        <div className="instruction-text">
                            5. If you want to fulfill the help list, Message or call (optional depending on whether or not a 
                            user lists a phone number) the person that created the Help List.
                        </div>
                        <div className="instruction-text">
                            6. To commit to the job, click 'OK, I'll do it', to see the whole job - a notification will be sent 
                            to the Help Seeker indicating that you have agreed to the job.
                        </div>
                    </div>
                </div>
            </div>
            <div className="instruction-item">
                <div className="instruction-image">
                    <img src="./images/Helper_Part3.3.png" />
                </div>
                <div className="instruction-description">
                    <div className="instruction-title">Helper - Pickup Items and Upload Receipt</div>
                    <div className="instruction-text" style={{textAlign: 'left'}}>
                        6. Gather the requested items and CHECK OFF the items that you were able to fulfill.
                    </div>
                    <div className="instruction-text instruction-text-mod">
                        7. Once you are done gathering items, click 'items picked up'.
                    </div>
                    <div className="instruction-text instruction-text-mod">
                        8. Take a photo and upload a receipt of the items picked up.
                    </div>
                </div>
            </div>
            <div className="instruction-item">
                <div className="instruction-image helper-final">
                    <div className="helper-final-image"><img src="./images/bag.png" /></div>
                    <div className="helper-final-image"><img src="./images/Money.png" /></div>                        
                </div>
                <div className="instruction-description">
                    <div className="instruction-title">Helper - Final Steps</div>
                    <div className="instruction-text" style={{textAlign: 'left'}}>
                        9. Deliver requested items to Help Seeker based on the arrangements you made via in-app message
                        or call (optional depending upon whether or not a user lists a phone number)
                    </div>
                    <div className="instruction-text instruction-text-mod">
                        10. Receive reimbursement based on valid digital receipt (See <a href="/safety">Best Practices</a>).
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Helpers;