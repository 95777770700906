import React from 'react';
import './styles.scss';

function SafetyPage() {
    return (
        <div className="safety-page">
            <div className="main-container">
                <div className="main-title-container">
                    <div className="main-title">
                        <div>Thank you!</div>
                        <div>It is truly fantastic that you are </div>
                        <div>using <span className="green-text">PitchN</span> to give & receive help.</div>
                    </div>
                    <div className="safety-banner">
                        <div className="safety-banner-inner">
                            <div className="disclaimer">
                                It is important to remember that during this health crisis we all need to exercise extreme caution.. even when giving 
                                or receiving help.
                            </div>
                            <div className="disclaimer">
                                It is always advisable to consult reputable sources such as the Center for Disease Control and the World Health 
                                Organization regarding safety measures and precautions.
                            </div>
                            <div className="side-note">
                                *The information below was not written by a medical expert and is only intended to provide an interpretation of the above 
                                organization's COVID-19 (SARS-CoV-2) recommendations as applied to the use of the PitchN app.
                            </div>
                        </div>
                    </div>

                    <div className="safety-precautions">
                        <div className="safety-tips">
                            <div className="title green-text">
                                Safety precautions while 
                            </div>
                            <div className="title green-text">
                                exchanging goods and/or supplies.
                            </div>
                            <div className="description">
                                <div>Never occupy the same space at the same time! </div>
                                <div>Never touch, shake hands, high-five, or even elbow-bump!</div>
                                <div>Remain at least 6 feet apart at all times during a help exchange.</div>
                                
                            </div>
                        </div>
                        <div className="safety-tips-image">
                            <img src="./images/Heart_location_green.png" />
                        </div>
                    </div>
                    <div className="safety-practices">
                        <div className="best-practices">
                            <div className="title green-text">
                                Best Practices
                            </div>
                            <div className="best-practices-steps">
                                <div className="single-step">
                                    <div className="step-image step-image1">
                                        <img src="./images/Receipt.png" />
                                    </div>
                                    <div className="step-description1">
                                        <div className="step-text1">
                                            We recommend that the Helper leaves the goods/supplies and receipt on a Help Seeker's doorstep
                                            for example or out in a hallway (or wherever makes sense).
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step">
                                    <div className="step-image step-image2">
                                        <img src="./images/6_Ft.png" />
                                    </div>
                                    <div className="step-description2">
                                        <div className="step-text2">
                                            We recommend that the Helper leaves the goods/supplies and receipt on a Help Seeker's doorstep
                                            for example or out in a hallway (or wherever makes sense).
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step">
                                    <div className="step-image step-image1">
                                        <img src="./images/Money.png" />
                                    </div>
                                    <div className="step-description1">
                                        <div className="step-text1">
                                            Help Seeker can sanitize and retrieve goods/supplies and leave money or a check to cover the expense 
                                            (absolutely <span className="red-text">no premiums</span> are allowed on any exchange).
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step">
                                    <div className="step-image step-image2">
                                        <img src="./images/bag.png" />
                                    </div>
                                    <div className="step-description2">
                                        <div className="step-text2">
                                            In the case of a good or supply provided free of charge, no check or cash will be exchanged.
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step">
                                    <div className="step-image step-image1">
                                        <img src="./images/6_Ft.png" />
                                    </div>
                                    <div className="step-description1">
                                        <div className="step-text1">
                                            Once the Help Seeker has moved at least 6 feet away from the cash or check left on the doorstep or wherever,
                                            the Helper may pick it up and sanitize it.
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step">
                                    <div className="step-image step-image2">
                                        <img src="./images/Bubble.png" />
                                    </div>
                                    <div className="step-description2">
                                        <div className="step-text2">
                                            Helper and Help Seeker may exchange pleasantries and maybe an "air" high five where no touching occurs!
                                        </div>
                                    </div>
                                </div>
                                <div className="single-step">
                                    <div className="step-image step-image1">
                                        <img src="./images/sanitizer.png" />
                                    </div>
                                    <div className="step-description1">
                                        <div className="step-text1">
                                            Helper and Help Seeker should sanitize mobile phones used throughout the Help Exchange.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="help-instructions">
                            <div className="instruction-steps-container">
                                <div className="instruction-steps">
                                    <div className="instruction-steps-inner">
                                        <div className="title">
                                            HELPERS
                                        </div>
                                        <div className="help-tip">
                                            Do not help if you are sick in any capacity.
                                        </div>
                                        <div className="help-tip">
                                            Wash your hands with soap and water for at least 20 seconds before handling any goods or supplies
                                            and going on a help mission.
                                        </div>
                                        <div className="help-tip">
                                            Do not touch your face when out on a help mission.
                                        </div>
                                        <div className="help-tip">
                                            Wear protective gear if possible. Keep a distance of at least 6 feet when around an individual or groups of people
                                            (including when you exchange goods and/or supplies with a Help Seeker).
                                        </div>
                                        <div className="help-tip">
                                            Sanitize any items that you receive in the exchange of goods and/or supplies (cash or check).
                                        </div>
                                        <div className="help-tip">
                                            Sanitize mobile phone used in the exchange.
                                        </div>
                                        <div className="help-tip">
                                            Remove clothes and shoes before you enter your residence and immediately wash with detergent and high heat.
                                        </div>
                                        <div className="help-tip">
                                            Sanitize any surfaces touched in your car or transportation means, and anything touched upon entering your residence.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="instruction-steps-container">
                                <div className="instruction-steps">
                                    <div className="instruction-steps-inner">
                                        <div className="title">
                                            HELP SEEKERS
                                        </div>
                                        <div className="help-tip">
                                            Before your exchange, wash your hands for at least 20 seconds with soap and water.
                                        </div>
                                        <div className="help-tip">
                                            When exchanging goods, keep a distance of at least of 6 feet from a helper at all times.
                                        </div>
                                        <div className="help-tip">
                                            Conside sanitizing a bag and all goods and/or supplies before bringing it into your residence.
                                        </div>
                                        <div className="help-tip">
                                            Sanitize mobile phone used throughout the Help Exchange.
                                        </div>
                                        <div className="help-tip">
                                            Wash your hands for at least 20 seconds after handling received goods and/or supplies and the Help Exchange is concluded. 
                                        </div>
                                    </div>
                                </div>
                                <div className="instructions-disclaimer">
                                    * This statement has not been evaluated by the Food and Drug Administration, CDC, WHO, or any infectious disease
                                    experts, medical professionals, or any experts of any kind. These recommendations are not intended to diagnose,
                                    treat cure or prevent any disease.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="download-section">
                        <div className="phone-images">
                            <img src="./images/app-screen.png" />
                            {/* <div className='main-phone'>
                                <img src="./images/Main_Phone2.png" />
                            </div>
                            <div className="other-phone">
                                <img src="./images/Main_Phone1.png" />
                            </div> */}
                        </div>
                        <div className="download-options">
                            <div className="sub-title">Download the app now!</div>
                            <div >

                            </div>
                            <div className="download-buttons">
                                <div className="download-button">
                                    <div className="apple-store">
                                        <img className="apple-badge" src="./images/apple-store.svg" />
                                    </div>
                                </div>
                                <div className="download-button">
                                    <img className="google-play-badge" src="./images/google-play-badge.png" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="volunteer-section">
                        <div className="section-title">
                            <div>PitchN lets those that</div>
                            <div>need help connect with</div>
                            <div>volunteer helpers.</div>
                        </div>
                    </div>
                    <div className="help-button-container volunteer-button-container">
                        <button className="help-button">
                            VOLUNTEER NOW
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SafetyPage;
