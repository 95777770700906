import React from 'react';

function HelpSeekers (props) {
    return (
        <div id="help-seekers" ref={props.refProp}>
            <div className="section-info">
                <div className="title">Help seekers</div>
                <div>
                Get help running errands like shopping for 
                </div>
                <div>
                    <span className="red-text-2">groceries, picking up medication, or gathering </span>
                </div>
                <div>
                    <span className="red-text-2">other critical supplies.</span> 
                </div>
            </div>
            <div className="instruction-item" >
                <div className="instruction-image">
                    <img src="./images/Help_seeker_step3.png" />
                </div>
                <div className="instruction-description">
                    <div className="instruction-title">Help Seeker</div>
                    <div className="instruction-text" style={{textAlign: 'left'}}>
                        1. Press the Help tab on the bottom left of the app.
                    </div>
                    <div className="instruction-text instruction-text-mod">
                        2. Select 'Need Help?'
                    </div>
                </div>
            </div>

            <div className="instruction-item-block">
                <div className="instruction-item2">
                    <div className="instruction-image">
                        <img src="./images/Help_Seeker_Part 3.1.png" />
                    </div>
                    <div className="instruction-title">Help Seeker - Make a List</div>
                    <div className="instruction-description">
                        <div className="instruction-text">3. Select one or more categories</div>
                        <div className="instruction-text">4. Click 'MAKE A LIST'</div>
                    </div>
                </div>
                <div className="instruction-item2">
                    <div className="instruction-image">
                        <img src="./images/Help_Seeker_Part3.2.png" />
                    </div>
                    <div className="instruction-title">Help Seeker - Publish your List</div>
                    <div className="instruction-description">
                        <div className="instruction-text">
                            5. Fill the form according to what you need, the name of the item and the quantity required.
                        </div>
                        <div className="instruction-text">
                            6. Click 'ADD TO LIST'
                        </div>
                        <div className="instruction-text">
                            7. Go through the list once more and check if all the items have been added, then click 'PUBLISH LIST'
                        </div>
                    </div>
                </div>
            </div>

            <div className="publish-list-container">
                <div className="publish-list">
                    <div className="publish-list-inner">
                        <div className="publish-list-info">
                            After the Help Seeker publishes the List, it will now appear on the GIVE HELP section, so HELPERS can spot 
                            your list and pitch in to help.
                            <div className="side-note">
                                (Lookout for a message notification)
                            </div>
                        </div>
                        <div className="publish-list-image">
                            <img src="./images/Give_help.png" />
                        </div>
                    </div>
                </div>
                <div className="notification-container">
                    <div className="notification">
                        <div className="notification-image"><img src="./images/notification.png" /></div>
                        <div className="notification-logo"><img src="./images/Login_Logo.png" /></div>
                        <div className="notification-text red-text">A volunteer has agreed to help you complete your list</div>
                    </div>
                </div>
            </div>

            <div className="instruction-item" >
                <div className="instruction-description">
                    {/* <div className="instruction-title">Help Seeker - Step 3</div> */}
                    <div className="instruction-text" style={{textAlign: 'left'}}>
                        8. Now you can make arrangements to receive the requested help using in-app messaging or via phone call.
                    </div>
                </div>
                <div className="instruction-image">
                    <img src="./images/Help_Seeker_Notification.png" />
                </div>
            </div>

            <div className="volunteer-section">
                <div className="volunteer-tips">
                    <div className="volunteer-info">
                        <div className="volunteer-tips-text">
                            After the Help Seeker publishes the List, it will now appear on the GIVE HELP section, so HELPERS can spot 
                            your list and pitch in to help.
                        </div>
                    </div>
                    <div className="volunteer-tips-image">
                        <img src="./images/Help_seekers_ volunteer.png" />
                    </div>
                </div>
            </div>

            <div className="instruction-item">
                <div className="instruction-image helper-final">
                    <div className="helper-final-image"><div className="items-picked" >Items Picked up</div></div>
                    <div className="helper-final-image"><img src="./images/List.png" /></div>
                    <div className="helper-final-image"><img src="./images/Money.png" /></div>                        
                </div>
                <div className="instruction-description">
                    <div className="instruction-title">Help Seeker - Final Steps</div>
                    <div className="instruction-text" style={{textAlign: 'left'}}>
                        9. You will then receive a notification that help list items were picked up.
                    </div>
                    <div className="instruction-text instruction-text-mod">
                        10. Receive attachment of digital photo of applicable receipt.
                    </div>
                    <div className="instruction-text instruction-text-mod">
                        11. Receive your help list items from the helper.
                    </div>
                    <div className="instruction-text instruction-text-mod">
                        10. Reimburse Helper if applicable (see <a href="/safety">Best Practices</a>) based on digital receipt.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelpSeekers;