import React from 'react';
import './styles.scss';

function Footer () {
    return (
        <footer className="footer">
            <div className="content">
                <div>
                    <div className="powered-by">Powered by</div>
                    <div className="r2-logo">
                    <a href="https://www.r2c.io/" ><img src="./images/R2CLogo.png" /></a>
                    </div>
                </div>
                <div className="social-media">
                    <a target="_blank" href="https://www.facebook.com/PitchN-107026257635275" ><img src="./images/facebook.svg" alt="" /></a>
                    <a target="_blank" href="https://www.instagram.com/pitchn_app/" ><img src="./images/instagram.svg" alt=""/></a>
                    <a target="_blank" href="https://twitter.com/PitchN2" ><img src="./images/twitter.svg" alt=""/></a>
                    <a target="_blank" href="https://pitchnapp.tumblr.com" ><img src="./images/tumblr.svg" alt=""/></a>
                    <a target="_blank" href="https://tiktok.com/@PitchNapp" ><img src="./images/tiktok.svg" alt=""/></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;