import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";
import Modal from 'react-responsive-modal';
import ContactForm from './ContactForm';
import WorkingPage from './WorkingPage';
import HomePage from './HomePage';
import SafetyPage from './SafetyPage';
import ScrollUpButton from "react-scroll-up-button";
import Footer from './Footer';
import './App.scss';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        }
    }

    onOpenModal = () => {
        this.setState({ modalOpen: true });
    }
     
    onCloseModal = () => {
        this.setState({ modalOpen: false });
    }

    render() { 
        const {modalOpen} = this.state;
        return (
            <Router>
                <div className="App">
                    <ScrollUpButton ContainerClassName="scroll-up"/>
                    <div className="title-nav">
                        <div className="title-name" onClick={() => window.location.href = '/'}>PitchN</div>
                        <div className="nav-links">
                            <a className="nav-link" href="/working">How It Works?</a>
                            <a className="nav-link" href="/safety">Safety</a>
                            <a className="nav-link" onClick={this.onOpenModal}>Contact Us</a>
                        </div>
                        <div className="title-logo" onClick={() => window.location.href = '/'}>
                            <img src="./images/PitchN_logo.png" alt="PitchN_logo"/>
                        </div>
                        <Modal classNames={{modal: "modal-container"}} open={modalOpen} onClose={this.onCloseModal} center>
                            <ContactForm />
                        </Modal>
                            <div className="social-media">
                                <a target="_blank" href="https://www.facebook.com/PitchN-107026257635275" ><img src="./images/facebook.svg" alt="" /></a>
                                <a target="_blank" href="https://www.instagram.com/pitchn_app/" ><img src="./images/instagram.svg" alt=""/></a>
                                <a target="_blank" href="https://twitter.com/PitchN2" ><img src="./images/twitter.svg" alt=""/></a>
                            </div>
                        
                    </div>
                    <Switch>
                        <Route exact path="/">
                            <HomePage />
                        </Route>
                        <Route exact path="/working">
                            <WorkingPage />
                        </Route>
                        <Route exact path="/safety">
                            <SafetyPage />
                        </Route>
                    </Switch>
                    <Footer />
                </div>
            </Router>
        )
    };
}

export default App;
