import React from 'react';
import './styles.scss';

function HomePage() {
    return (
        <div className="homepage">
            <div className="main-container">
                <div className="coming-soon">
                    <div className="content">Coming Soon!</div>
                </div>
                <div className="main-title-container">
                    <div className="download-section">
                        <div className="phone-images">
                            <img src="./images/app-screen.png" />
                            <div className="credits">
                                <img src="./images/Built_by_r2.png" />
                            </div>
                        </div>
                        <div className="download-options">
                            <div className="main-title">
                                <div>PitchN, The <span className="danger">Covid-19</span></div>
                                <div>Help App</div>
                            </div>  
                            <div className="caption">Give and Receive help during this health crisis.</div>
                            <div className="download-buttons">
                                <img src="./images/download_buttons_blue.png" />
                                {/* <div className="download-button">
                                    <img className="google-play-badge" src="./images/apple-store.svg" />
                                </div>
                                <div className="download-button">
                                    <img className="google-play-badge" src="./images/google-play-badge.png" />
                                </div> */}
                            </div>
                            <div className="sub-title red-text">
                                <div className="danger">Fight coronavirus as a community</div>
                            </div>
                        </div>
                    </div>                    
                </div>
            

                <div className="help-section">
                    <div className="help-section-inner">
                        <div className="help-graphic">
                            <div className="bike-ride"><img src="./images/Flag.png" /></div>
                            {/* <div className="flag"><img src="./images/Flag.png" /></div> */}
                        </div>
                        <div className="help-text">
                            <div>Help the elderly, Help Medical Professionals,</div>
                            <div>Help the immunocompromised,</div>
                            <div>Help the hungry, <span className="danger">Help anyone in need</span></div>
                        </div>
                        <div className="help-instructions">
                            <div className="doctor-graphic" >
                                <div className="doctor-graphic-inner">
                                    <img src="./images/Doctors.png" />
                                    <div>
                                        Thank them for their constant support by sharing and lending your care
                                    </div>
                                </div>
                            </div>
                            <div className="help-description">
                                <div className="help-info">The novel coronavirus pandemic impacts us all. It influences every aspect of society from education to commerce.
                                    As the virus rages on, many individuals and families are currently in need of help. And many more will require help
                                    in the near future.
                                </div>
                                <div className="help-info">Some individuals bear far greater health risk if infected with COVID-19 such as the elderly, the immunocompromised,
                                    those with pre-existing health conditions etc.
                                </div>
                                <div className="help-info">Heroic medical professionals fighting this health crisis on the frontlines are too busy saving lives to stock up
                                    on essential items like food and supplies. These courageous individuals are also in great need of protective respirators
                                     and latex gloves. 
                                </div>
                            </div>
                        </div>
                        <div className="helping-hand">
                            <div className="">
                                Lend a helping hand in whatever capacity possible and  
                            </div>
                            <div>
                                let’s work together to make a positive difference.
                            </div>
                        </div>
                        <div className="help-call">
                            <div className="help-action-call">
                                Many individuals and families are in need of critical items like <span className="highlight">baby formula and essential medication</span>. 
                                These people and many others need help. <span className="highlight">People helping people</span> is a critical piece in combating COVID-19. Lend a helping hand in whatever 
                                capacity possible and let's work together to make a positive difference.
                            </div>
                            <div className="fistbump">
                                <img src="./images/fistbump.png" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
                <div className="app-instructions">
                    <div className="app-instructions-inner">
                        <div className="app-info">
                            <div className="info-title blue-text">Need Help</div>
                            <div className="info-image"><img src="./images/help_seekers.png" /></div>
                            <div className="info-description"><span className="danger">Help Seekers</span> 
                                <div className="info-text">
                                    Get help running errands like shopping for groceries, picking up medication, or gathering 
                                    other critical supplies.  
                                </div>
                            </div>
                        </div>
                        <div className="app-info">
                            <div className="info-title blue-text">Give Help</div>
                            <div className="info-image"><img src="./images/helpers.png" /></div>
                            <div className="info-description"><span className="danger">Helpers/Volunteers</span> 
                                <div className="info-text">
                                    Help those that need it most by running errands in your local community.
                                </div>
                            </div>
                        </div>
                        <div className="app-info">
                            <div className="info-title blue-text">Pantry</div>
                            <div className="info-image"><img src="./images/pantry.png" /></div>
                            <div className="info-description"><span className="danger">Community Marketplace</span> 
                                <div className="info-text">
                                    Share critical items with others in your community like baby formula, diapers, baby wipes, toilet paper etc.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="news">
                    <div className="title">
                        PitchN in the news
                    </div>
                    <div className="news-articles">
                        <div className="news-card">
                            <div className="card-image">
                                <img className="" src="./images/tie-logo.png" />
                            </div>
                            <div className="card-title">
                                TiE Denver
                            </div>
                            <div className="card-content">
                                During this global crisis, many people are willing to help… they just need a better way!

                                #TiEDenver May Spotlight company is making a way. 
                                See how PitchN Founders and TiE Members, Aswin Ramakrishnan, and Brian Ritter, MBA, are making an impact at our Spotlight page!
                                <br />
                                <a href="https://lnkd.in/e7QnXu4">https://lnkd.in/e7QnXu4</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="volunteer-helper">
                    <div className="title">
                        Download the app now!
                    </div>
                    <div className="volunteer-download">
                        <div className="volunteer-image">
                            <img src="./images/Volunteer.png" />
                        </div>
                        <div className="download-options">
                            <div className="qr-code">
                                <img src="./images/qr.png" />
                            </div>
                            <div className="download-buttons">
                                <div className="download-button">
                                    <img src="./images/download_buttons_green.png" />
                                </div>
                            </div>
                            <div className="credits">
                                <img src="./images/Built_by_r2.png" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="volunteer-section">
                    <div className="section-title">
                        <div>PitchN lets those that</div>
                        <div>need help connect with</div>
                        <div>volunteer helpers.</div>
                    </div>
                </div>
                <div className="help-button-container volunteer-button-container">
                    <button className="help-button">
                        VOLUNTEER NOW
                    </button>
                </div>
        </div>
    )
}

export default HomePage;