import React from 'react';
import Collapsible from 'react-collapsible';
import Helpers from './Helpers';
import HelpSeekers from './HelpSeekers';
import Pantry from './Pantry';
import './styles.scss';
import './collapsible.scss';

class WorkingPage extends React.Component {

    constructor() {
        super();
        this.helpSeekerRef = React.createRef();
        this.helperRef  = React.createRef();
        this.pantryRef = React.createRef();
        this.state = {
            activeTab: 0
        }
    }

    setActiveTab(activeTab, ref) {
        this.setState({
            activeTab 
        }, function() {
            window.scrollTo({
                top: ref.current.offsetTop,
                behavior: 'smooth'
              })
        })        
    }

    render() {
        return (
            <div className="working-page">
                <div className="title-container">
                    <div className="main-title-container">
                        <div className="main-title-image">
                            <img src="./images/Main_Phone1.png" />
                        </div>
                        <div className="title-container">
                            <div className="main-title">
                                How PitchN works
                            </div>
                            <div className="sub-title">
                                <div>
                                    Follow these 4 steps to successfully
                                </div>
                                <div>
                                    Give Help, Get Help, or Share critical items in our Pantry. 
                                </div>
                                <div>
                                    More detailed instructions can be found by clicking the blue buttons below.
                                </div>  
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className="feature-details">
                    <div className="features-overview">
                        <div className="feature-step-number first-step">
                            <div className="step-line">
                                <div className="step-number first-step-number">1</div> 
                            </div>
                        </div>
                        <div className="feature-step">
                            <div className="feature-step-image login-image">
                                <img src="./images/login_images.png" />
                            </div>
                            <div className="feature-step-text">
                                <strong>Login</strong> with Facebook, Instagram or LinkedIn or Create an Account
                            </div>
                        </div>
                        <div className="feature-step-number">
                            <div className="step-line">
                                <div className="step-number">2</div> 
                            </div>
                        </div>
                        <div className="feature-step">
                            <div className="feature-step-image step2-image">
                                <img src="./images/step2.png" />
                            </div>
                            <div className="feature-step-text">
                                Select How you want to use PitchN - 1. <strong>Give Help</strong> - 
                                2. <strong>Need Help</strong> - 
                                3. <strong>Pantry</strong>
                            </div>
                        </div>
                        <div className="feature-step-number">
                            <div className="step-line">
                                <div className="step-number">3</div> 
                            </div>
                        </div>
                        <div className="feature-step">
                            <div className="feature-step-image connect-image">
                                <img src="./images/connect.png" />
                            </div>
                            <div className="feature-step-text">
                                <strong>Connect</strong> based on distance apart & <strong>Message</strong> to make arragements
                            </div>
                        </div>
                        <div className="feature-step-number">
                            <div className="step-line">
                                <div className="step-number">4</div> 
                            </div>
                        </div>
                        <div className="feature-step step4">
                            <div className="feature-step-image receipt-image">
                                <div className="reimburse-image">
                                    <div className="money-image">
                                        <div className="pay-alt"><img src="./images/check.png" /></div>
                                        <div className="pay-alt"><img src="./images/cash.png" /></div>
                                    </div>
                                    <div className="electronic-image">
                                        <div className="pay-alt"><img src="./images/paytm.png" /></div>
                                        <div className="pay-alt"><img src="./images/venmo.png" /></div>
                                        <div className="pay-alt"><img src="./images/paypal.png" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="feature-step-text">
                                Reimburse/Upload Receipt
                            </div>
                        </div> 
                    </div>                
                </div>

                {/* <div className="payment-platforms">
                    <div className="payment-options">
                        <div className="payment-image">
                            <img src="./images/payment_icons.png" />
                        </div>
                        <div className="payment-info">
                            <div className="payment-portals"><img src="./images/payment.png" /></div>
                            <div className="payment-description">
                                <div>
                                    PitchN supports P2P payment platforms.  
                                </div>
                                <div>
                                    Make reimbursement with <span className="blue-text-2">cash, check or</span>
                                </div> 
                                <div className="blue-text-2">
                                    Venmo, Paytm, PayPal
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="start-helping">
                        <div>
                            Need Help. Give Help.
                        </div>
                        <div>
                            Start Helping Now with PitchN.
                        </div>
                    </div>
                </div> */}

                <div className="starter-instructions">
                    <div className="starter-options">
                        <div className="option-item" onClick={(e) => this.setActiveTab(1, this.helpSeekerRef)}>
                            <div>Help Seekers<span className="down-arrow"><img src="./images/caret_down.png" /></span></div>
                            <div className="white-text">
                                (Need Help)
                            </div>
                        </div>
                        <div className="option-item" onClick={(e) => this.setActiveTab(2, this.helperRef)}>
                            <div>Helpers/Volunteers<span className="down-arrow"><img src="./images/caret_down.png" /></span></div>
                            <div className="white-text">
                                (Give Help)
                            </div>
                        </div>
                        <div className="option-item" onClick={(e) => this.setActiveTab(3, this.pantryRef)}>
                            <div>Pantry<span className="down-arrow"><img src="./images/caret_down.png" /></span></div>
                            <div className="white-text">
                                Share Locally
                            </div>
                        </div>
                    </div>
                    <div className={`open-section ${this.state.activeTab === 1 ? '' : 'hidden'}`}>
                        <HelpSeekers refProp={this.helpSeekerRef}/>
                    </div>
                    <div className={`open-section ${this.state.activeTab === 2 ? '' : 'hidden'}`}>
                        <Helpers refProp={this.helperRef}/>
                    </div>
                    <div className={`open-section ${this.state.activeTab === 3 ? '' : 'hidden'}`}>
                        <Pantry refProp={this.pantryRef}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default WorkingPage;