import React from 'react';

function Pantry (props) {
    return (
        <div className="pantry" id="pantry" ref={props.refProp}>
            <div className="section-info">
                <div className="title">Community Marketplace</div>
                <div>
                Share critical items with others in your community like baby 
                </div>
                <div>
                formula, diapers, baby wipes, toilet paper etc.
                </div>
                <div>
                    <span className="red-text-2">Share what you can spare.</span>
                </div>
            </div>
            <div className="pantry-content">
                <div className="title">
                    How to use Pantry
                </div>
                <div className="pantry-first-steps">
                    <div className="first-step-images"><img src="./images/Pantry-1.png" /></div>
                    <div className="first-step-images"><img src="./images/Pantry-2.png" /></div>
                    <div className="first-step-images"><img src="./images/Pantry-3.png" /></div>
                </div>
                <div className="pantry-first-steps">
                    <div className="instruction-description">
                            <div className="instruction-text">1. Navigate to the PANTRY tab on the botom right of the screen.</div>
                            <div className="instruction-text">2. Search the Panty for critically needed goods and/or supplies</div>
                            <div className="instruction-text">3. To search for an item, use the 
                                tab located on the top of the screen. Items can be filtered based on category,  distance and availability.</div>
                            <div className="instruction-text">4. Once you find an item, message the person that listed it.</div>
                    </div>
                    <div className="instruction-description">
                        <div className="instruction-text">5. Message Helper (person offering good or supply) with in-app messaging or via a call (optional 
                            depending upon whether or not a user lists a phone number) to arrange to meet for good and/or supply exchange. </div>
                        <div className="instruction-text">6. Arrange to acquire the needed item (see Best Practices)</div>
                        <div className="side-note red-text">* Do not offer to make any payment of any kind or any reimbursement in return for
                         provided pantry item(s) </div>
                    </div>
                </div>
                <div className="title">
                    How to add items to the Pantry list
                </div>
                <div className="instruction-item-block">
                    <div className="instruction-item2">
                        <div className="instruction-image">
                            <img src="./images/Pantry-4.png" />
                        </div>
                        <div className="instruction-description">
                            <div className="instruction-text">1. Navigate to the PANTRY at the bottom right of the screen.</div>
                            <div className="instruction-text">2. Click the + icon</div>
                            <div className="instruction-text">2.1. On the form, Name the item</div>
                            <div className="instruction-text">2.2. Select the category</div>
                            <div className="instruction-text">2.3. Add the quantity of the item.</div>
                            <div className="instruction-text">3. When Finished, Publish the item to the pantry.</div>
                        </div>
                    </div>
                    <div className="instruction-item2">
                        <div className="instruction-image">
                            <img src="./images/Pantry-5.png" />
                        </div>
                        <div className="instruction-description">
                            <div className="instruction-text">
                                4. Help Seeker will Message or Call you to arrange to meet for goods and/or supply exchange.
                            </div>
                            <div className="instruction-text">
                                5. Deliver your Pantry item(s)
                            </div>
                            <div className="side-note red-text">* No Payment of any kind or Reimbursement is to be provided 
                            to a Helper in return for Pantry goods/supplies. These are to be shared free of any cost.
                            (see Best Practices).</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pantry;